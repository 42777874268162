import { defineStore } from "pinia";

type Language = "en" | "ru"; //app display language
type StudyLanguage = "EN" | "RU"; //selected language for studying, in which language the courses are fetched.

interface userState {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  enabled: boolean | null;
  billingDate: string | null;
  lastLogin: string | null;
  lastPayment: string | null;
  role: string | null;
  tier: string | null;
  language: Language;
  selectedStudyLang: StudyLanguage;
}

export const useUserStore = defineStore({
  id: "user",
  state: (): userState => ({
    id: JSON.parse(localStorage.getItem("id") || "null"),
    firstName: JSON.parse(localStorage.getItem("firstName") || "null"),
    lastName: JSON.parse(localStorage.getItem("lastName") || "null"),
    email: JSON.parse(localStorage.getItem("email") || "null"),
    enabled: JSON.parse(localStorage.getItem("enabled") || "null"),
    billingDate: JSON.parse(localStorage.getItem("billingDate") || "null"),
    lastLogin: JSON.parse(localStorage.getItem("lastLogin") || "null"),
    lastPayment: JSON.parse(localStorage.getItem("lastPayment") || "null"),
    role: JSON.parse(localStorage.getItem("role") || "null"),
    tier: JSON.parse(localStorage.getItem("tier") || "null"),
    language: (localStorage.getItem("language") as Language) || "ru",
    selectedStudyLang:
      (localStorage.getItem("selectedStudyLang") as StudyLanguage) || "RU",
  }),
  actions: {
    saveProfile(
      id: string | null,
      firstName: string | null,
      lastName: string | null,
      email: string | null,
      enabled: boolean | null,
      billingDate: string | null,
      lastLogin: string | null,
      lastPayment: string | null,
      role: string | null,
      tier: string | null,
    ) {
      this.id = id;
      this.firstName = firstName;
      this.lastName = lastName;
      this.email = email;
      this.enabled = enabled;
      this.billingDate = billingDate;
      this.lastLogin = lastLogin;
      this.lastPayment = lastPayment;
      this.role = role;
      this.tier = tier;

      localStorage.setItem("id", JSON.stringify(id));
      localStorage.setItem("firstName", JSON.stringify(firstName));
      localStorage.setItem("lastName", JSON.stringify(lastName));
      localStorage.setItem("email", JSON.stringify(email));
      localStorage.setItem("enabled", JSON.stringify(enabled));
      localStorage.setItem("billingDate", JSON.stringify(billingDate));
      localStorage.setItem("lastLogin", JSON.stringify(lastLogin));
      localStorage.setItem("lastPayment", JSON.stringify(lastPayment));
      localStorage.setItem("role", JSON.stringify(role));
      localStorage.setItem("tier", JSON.stringify(tier));
    },

    setLanguage(lang: Language) {
      this.language = lang;
      localStorage.setItem("language", lang);
    },

    setSelectedStudyLang(lang: StudyLanguage) {
      this.selectedStudyLang = lang;
      localStorage.setItem("selectedStudyLang", lang);
    },
  },
});
